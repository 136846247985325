
	import AdvertiserHeader from "./AdvertiserHeader.vue";
	import Vue from "vue";

	export default Vue.extend({
		name: "Advertisers",
		props: {},
		components: { AdvertiserHeader },
		data: () => ({}),
		created() {},
		async mounted() {},
		computed: {},
		methods: {},
	});
